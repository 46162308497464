import React from "react";

import { ReactComponent as CloudIcon } from "@mobsuccess-devops/streamline/custom/asset-manager/cloud.svg";
import { ReactComponent as UploadIcon } from "@mobsuccess-devops/streamline/custom/asset-manager/small_upload.svg";

import PropTypes from "prop-types";
import styled from "styled-components";

import Button from "../Button";
import Paragraph from "../Paragraph";

const FooterUI = styled.footer`
  display: flex;
  width: 100%;
  height: 20vh;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding-top: 32px;
  place-items: center;
`;

FooterUI.displayName = "FooterUI";

function Footer({ metadata, onOpen, onSubmitForm, currentStatus }) {
  return (
    <FooterUI>
      <Button
        onClick={currentStatus === 1 ? onSubmitForm : onOpen}
        text={
          currentStatus === 1 || currentStatus === 2
            ? "Envoyer dans le cloud"
            : "Parcourir les fichiers"
        }
        icon={metadata.length ? <CloudIcon /> : <UploadIcon />}
        disabled={currentStatus === 2}
      />
      {metadata.lenght ? (
        <Paragraph onClick={onOpen} text="Parcourir d'autres fichiers" />
      ) : null}
    </FooterUI>
  );
}

Footer.propTypes = {
  metadata: PropTypes.arrayOf(PropTypes.object).isRequired,
  onOpen: PropTypes.func.isRequired,
  onSubmitForm: PropTypes.func.isRequired,
  currentStatus: PropTypes.number.isRequired,
};

export default Footer;
