import React, { useCallback } from "react";

import PropTypes from "prop-types";
import { useClipboard } from "use-clipboard-copy";

import Result from "./Result";

function ResultBase({ url }) {
  const clipboard = useClipboard();

  const handleClipboardCopy = useCallback(() => {
    clipboard.copy(encodeURI(url));
  }, [url, clipboard]);

  return <Result url={url} onClipboard={handleClipboardCopy} />;
}

ResultBase.propTypes = {
  url: PropTypes.string.isRequired,
};

export default ResultBase;
