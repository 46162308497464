import React, { useState, useCallback } from "react";

import PropTypes from "prop-types";

import Copy from "./Copy";

function CopyBase({ onClick }) {
  const [open, setOpen] = useState(false);

  const handleTooltipClose = useCallback(() => {
    setOpen(false);
  }, []);

  const handleTooltipOpen = useCallback(() => {
    setOpen(true);
  }, []);

  const handleIconClick = useCallback(() => {
    onClick();
    setOpen(true);
  }, [onClick]);

  return (
    <Copy
      onClick={onClick}
      open={open}
      onTooltipClose={handleTooltipClose}
      onTooltipOpen={handleTooltipOpen}
      onIconClick={handleIconClick}
    />
  );
}

CopyBase.propTypes = {
  onClick: PropTypes.func,
};

CopyBase.defaultProps = {
  onClick: () => null,
};

export default CopyBase;
