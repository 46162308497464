const theme = {
  borderRadius: "3.125rem",
  colors: {
    abbey: "#FFD987",
    black: "#000000",
    coral: "#FF8C51",
    gray: {
      darkGray: "#575757",
      lightGray: "#F4F4F4",
      normalGray: "#C7C7C7",
      numberGray: "#C2C2C2",
    },
    pink: "#FFC9C1",
    viking: "#54D5C0",
    white: "#FFFFFF",
  },
  fontSize: {
    extend: "1.125rem",
    normal: "1rem",
    title: "2.125rem",
    titleMobile: "1.875rem",
    xl: "1.5rem",
  },
};

export default theme;
