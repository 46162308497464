import React from "react";

import PropTypes from "prop-types";
import styled from "styled-components";

const ParagraphUI = styled.p`
  color: ${({ theme }) => theme.colors.gray.normalGray};
`;

ParagraphUI.displayName = "ParagraphUI";

function Paragraph({ text, onClick }) {
  return <ParagraphUI onClick={onClick}>{text}</ParagraphUI>;
}

Paragraph.propTypes = {
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func,
};

Paragraph.defaultProps = {
  onClick: () => null,
};

export default Paragraph;
