import React from "react";

import { ReactComponent as TypeImageIcon } from "@mobsuccess-devops/streamline/custom/asset-manager/type_image.svg";

import PropTypes from "prop-types";
import styled from "styled-components";

import { getFilesSize } from "../../../features/files/files";

const HeaderComponentUI = styled.header`
  display: flex;
  margin-bottom: 16px;
  place-items: center;
`;

HeaderComponentUI.displayName = "HeaderComponentUI";

const FileNumberUI = styled.div`
  padding: 5.2px 10px;
  margin: 0 8px 0 16px;
  background: ${({ theme }) => theme.colors.viking};
  border-radius: 50%;
  color: ${({ theme }) => theme.colors.white};
`;

FileNumberUI.displayName = "FileNumberUI";

const FileTypeUI = styled.p`
  border-radius: 50%;
  color: ${({ theme }) => theme.colors.gray.darkGray};
  font-weight: 600;
`;

FileTypeUI.displayName = "FileTypeUI";

const TotalSizeUI = styled.p`
  margin-left: 16px;
  color: ${({ theme }) => theme.colors.gray.numberGray};
`;

TotalSizeUI.displayName = "TotalSizeUI";

function Header({ metadata }) {
  return (
    <HeaderComponentUI>
      <img src={TypeImageIcon} alt="" />
      <FileNumberUI>{metadata.length}</FileNumberUI>
      <FileTypeUI>{metadata.length > 1 ? "Fichiers" : "Fichier"}</FileTypeUI>
      <TotalSizeUI>{getFilesSize(metadata)} ko</TotalSizeUI>
    </HeaderComponentUI>
  );
}

Header.propTypes = {
  metadata: PropTypes.arrayOf(
    PropTypes.shape({
      base64: PropTypes.string,
      dimensions: PropTypes.objectOf(PropTypes.number),
      name: PropTypes.string,
      size: PropTypes.number,
    })
  ).isRequired,
};

export default Header;
