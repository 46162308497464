/* eslint-disable @mobsuccess-devops/mobsuccess/member-expressions */
import React, { useCallback, useState } from "react";

import { useDropzone } from "react-dropzone";
import { useToasts } from "react-toast-notifications";
import styled from "styled-components";

import Files from "../components/Files";
import Footer from "../components/Footer";
import Header from "../components/Header";
import Main from "../components/Main";
import { readImageAsync } from "../features/files";
import { readZipAsync } from "../features/files";
import { readVideoAsync } from "../features/files";

const changerBorderColor = (props) => {
  if (props.isDragAccept) {
    return "#575757";
  }
  return "#C7C7C7";
};

const ContainerUI = styled.div`
  display: flex;
  height: calc(100vh - 20px);
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  border-width: 8px;
  border-style: dashed;
  border-color: ${(props) => changerBorderColor(props)};
  border-radius: 5px;
  transition: border 0.15s ease-in-out;
`;

ContainerUI.displayName = "ContainerUI";

const Uploader = () => {
  const [metadata, setMetadata] = useState([]);
  const [links, setLinks] = useState([]);
  const [currentStatus, setCurrentStatus] = useState(0);

  const { addToast } = useToasts();

  const handleDrop = useCallback(
    async (acceptedFiles) => {
      let newMetadata = [];
      if (links.length === 0) {
        newMetadata = [...metadata];
      }
      await Promise.all(
        acceptedFiles.map(async (file) => {
          let fileParsed = null;
          let mimeParsed = file.type.split("/");

          if (file.type === "application/zip") {
            fileParsed = await readZipAsync(file);
          } else if (mimeParsed[0] === "image") {
            fileParsed = await readImageAsync(file);
          } else if (mimeParsed[0] === "video") {
            fileParsed = await readVideoAsync(file);
          } else {
            // eslint-disable-next-line @mobsuccess-devops/mobsuccess/variables
            return new Promise((_, reject) =>
              reject("Ce type de fichier n'est pas pris en charge.")
            );
          }
          newMetadata.push(fileParsed);
        })
      ).catch((error) => {
        if (typeof error === "object" && error !== null) {
          error = "Ce fichier n'est pas compatible.";
        }
        addToast(`Erreur: ${error}`, { appearance: "error" });
      });

      if (newMetadata.length) {
        setLinks([]);
        setMetadata(newMetadata);
        setCurrentStatus(1);
      }
    },
    [links.length, metadata, addToast]
  );

  const handleSetCurrentStatus = useCallback(
    (status) => {
      setCurrentStatus(status);
    },
    [setCurrentStatus]
  );

  const { getRootProps, getInputProps, open, isDragAccept, isDragActive } =
    useDropzone({
      onDrop: handleDrop,
      noClick: true,
      noKeyboard: true,
    });

  const handleSubmitForm = useCallback(async () => {
    setCurrentStatus(2);
  }, []);

  const handleOpen = useCallback(() => {
    open();
  }, [open]);

  return (
    <ContainerUI {...getRootProps({ isDragAccept, isDragActive })}>
      <input {...getInputProps()} />
      <Header />
      {metadata.length ? (
        <Files
          metadata={metadata}
          currentStatus={currentStatus}
          onSetCurrentStatus={handleSetCurrentStatus}
        />
      ) : (
        <Main onClick={handleOpen} />
      )}
      <Footer
        metadata={metadata}
        onSubmitForm={handleSubmitForm}
        onOpen={handleOpen}
        currentStatus={currentStatus}
      />
    </ContainerUI>
  );
};

export default Uploader;
