import React from "react";

import PropTypes from "prop-types";
import styled from "styled-components";

const ButtonComponentUI = styled.button`
  display: inline-flex;
  align-items: center;
  padding: 16px 32px;
  border: none;
  background: ${({ theme }) => theme.colors.abbey};
  border-radius: ${({ theme }) => theme.borderRadius};
  color: ${({ theme }) => theme.colors.gray.darkGray};
  cursor: pointer;
  font-size: ${({ theme }) => theme.fontSize.extend};
  font-weight: 600;
  ${(props) =>
    props.disabled
      ? `
          background: #c7c7c7;
          cursor: not-allowed;
        `
      : null}
`;

ButtonComponentUI.displayName = "ButtonComponentUI";

const TextUI = styled.p`
  margin-left: 24px;
`;

TextUI.displayName = "TextUI";

function Button({ icon, text, onClick, isUploading }) {
  return (
    <ButtonComponentUI onClick={onClick} disabled={isUploading}>
      {icon}
      <TextUI>{text}</TextUI>
    </ButtonComponentUI>
  );
}

Button.propTypes = {
  icon: PropTypes.node,
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  isUploading: PropTypes.bool,
};

Button.defaultProps = {
  icon: null,
  onClick: () => null,
  isUploading: false,
};

export default Button;
