import React from "react";

import PropTypes from "prop-types";
import styled from "styled-components";

import Copy from "../../../Copy";

const ParagraphImageUI = styled.div`
  display: flex;
  margin-bottom: 8px;
  color: ${({ theme }) => theme.colors.viking};
`;

ParagraphImageUI.displayName = "ParagraphImageUI";

const LinkUI = styled.a`
  display: flex;
  align-items: center;
  color: inherit;
  text-decoration: none;
`;

LinkUI.displayName = "LinkUI";

function Result({ url, onClipboard }) {
  return (
    <ParagraphImageUI>
      <LinkUI href={encodeURI(url)} target="_blank">
        {url.split("/").pop()}
      </LinkUI>
      <Copy onClick={onClipboard} />
    </ParagraphImageUI>
  );
}

Result.propTypes = {
  url: PropTypes.string.isRequired,
  onClipboard: PropTypes.func.isRequired,
};

export default Result;
