import React from "react";

import PropTypes from "prop-types";
import styled from "styled-components";

import ButtonUpload from "../ButtonUpload";
import Paragraph from "../Paragraph";
import Title from "../Title";

const MainComponentUI = styled.main`
  display: flex;
  width: 100%;
  height: 60vh;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
`;

MainComponentUI.displayName = "MainComponentUI";

function Main({ onClick }) {
  return (
    <MainComponentUI>
      <ButtonUpload onClick={onClick} />
      <Title>Glissez et déposez des fichiers ici</Title>
      <Paragraph text="ou" />
    </MainComponentUI>
  );
}

Main.propTypes = {
  onClick: PropTypes.func,
};

Main.defaultProps = {
  onClick: () => null,
};

export default Main;
