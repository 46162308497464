import React from "react";

import PropTypes from "prop-types";
import styled from "styled-components";

import { getFileSize } from "../../../../features/files/files";
import ProgressBar from "../../../ProgressBar";
import Links from "../../Links";

const FileListUI = styled.div`
  display: flex;
  width: calc(100% - 56px);
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 16px;
  margin-left: 56px;
`;

FileListUI.displayName = "FileListUI";

const FileDataUI = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 16px;
`;

FileDataUI.displayName = "FileDataUI";

const FileRightUI = styled.div`
  flex: 1;
`;

FileRightUI.displayName = "FileRightUI";

const MetadataUI = styled.div`
  display: flex;
  flex-direction: row;
`;

MetadataUI.displayName = "MetadataUI";

const TextUI = styled.p`
  margin-left: 16px;
  color: ${({ theme }) => theme.colors.gray.numberGray};
`;

TextUI.displayName = "TextUI";

const ImgContainerUI = styled.div`
  display: flex;
  width: 80px;
  height: 80px;
  justify-content: center;
  margin-right: 16px;
  & > img {
    width: 100%;
    height: 100%;
    border-radius: 5px;
    object-fit: contain;
  }
`;

ImgContainerUI.displayName = "ImgContainerUI";

function File({ file, result, currentStatus, uploadProgress }) {
  return (
    <FileListUI>
      <FileDataUI>
        <ImgContainerUI>
          <img alt={file.name} src={file.base64} />
        </ImgContainerUI>
        <FileRightUI>
          <span>{file.name}</span>
          {result ? <Links links={result} /> : null}
        </FileRightUI>
        <MetadataUI>
          {file.dimensions.width !== null && file.dimensions.height !== null ? (
            <TextUI>
              {file.dimensions.width} x {file.dimensions.height}
            </TextUI>
          ) : null}
          <TextUI>{getFileSize(file)} ko</TextUI>
        </MetadataUI>
      </FileDataUI>
      {result === null ? (
        currentStatus !== 1 ? (
          <ProgressBar percent={uploadProgress} />
        ) : null
      ) : null}
    </FileListUI>
  );
}

File.propTypes = {
  file: PropTypes.shape({
    base64: PropTypes.string,
    dimensions: PropTypes.objectOf(PropTypes.number),
    name: PropTypes.string,
    size: PropTypes.number,
  }).isRequired,
  result: PropTypes.shape({
    url: PropTypes.string,
    metadata: PropTypes.string,
    warning: PropTypes.objectOf(
      PropTypes.objectOf(PropTypes.arrayOf(PropTypes.string))
    ).isRequired,
    error: PropTypes.arrayOf(PropTypes.string),
    type: PropTypes.string.isRequired,
  }),
  currentStatus: PropTypes.number.isRequired,
  uploadProgress: PropTypes.number,
};

File.defaultProps = {
  uploadProgress: 0,
  result: null,
};

export default File;
