import React from "react";

import styled from "styled-components";

import Logo from "../Logo";

const HeaderContainerUI = styled.header`
  display: flex;
  width: 100%;
  height: 20vh;
  align-items: center;
  justify-content: center;
`;

HeaderContainerUI.displayName = "HeaderContainerUI";

function Header() {
  return (
    <HeaderContainerUI>
      <Logo />
    </HeaderContainerUI>
  );
}

export default Header;
