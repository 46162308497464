import React from "react";

import PropTypes from "prop-types";
import styled from "styled-components";

import ResultMedia from "./ResultMedia";
import ResultZip from "./ResultZip";

const LinksComponentUI = styled.div`
  margin-top: 16px;
`;

LinksComponentUI.displayName = "LinksComponentUI";
function Links({ links }) {
  return (
    <LinksComponentUI>
      {links.type === "image" || links.type === "video" ? (
        <ResultMedia links={links} />
      ) : (
        <ResultZip links={links} />
      )}
    </LinksComponentUI>
  );
}

Links.propTypes = {
  links: PropTypes.objectOf(PropTypes.oneOfType([() => null, PropTypes.string]))
    .isRequired,
};

export default Links;
