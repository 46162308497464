import React from "react";

import propTypes from "prop-types";
import styled from "styled-components";

const ProgressBarCommonUI = styled.div`
  height: 20px;
  border-radius: 50px;
`;
ProgressBarCommonUI.displayName = "ProgressBarCommonUI";

const ProgressBarWrapper = styled(ProgressBarCommonUI)`
  width: 100%;
  margin: 20px 0;
  background-color: #f7d283;
`;
ProgressBarWrapper.displayName = "ProgressBarWrapper";

const ProgressBarFill = styled(ProgressBarCommonUI)`
  display: flex;
  width: ${(props) => `${props.percent}%`};
  align-items: center;
  padding-left: 16px;
  background-color: #55d5bf;
  color: #fff;
`;
ProgressBarFill.displayName = "ProgressBarFill";

function ProgressBar({ percent }) {
  return (
    <ProgressBarWrapper>
      <ProgressBarFill percent={percent}>
        {percent === 100 ? "Traitement en cours..." : null}
      </ProgressBarFill>
    </ProgressBarWrapper>
  );
}

ProgressBar.propTypes = {
  percent: propTypes.number,
};

ProgressBar.defaultProps = {
  percent: 0,
};

export default ProgressBar;
