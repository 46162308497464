import React, { useCallback, useMemo } from "react";

import PropTypes from "prop-types";
import { useClipboard } from "use-clipboard-copy";

import ResultZip from "./ResultZip";

function ResultZipBase({ links }) {
  const clipboard = useClipboard();

  const handleClipboardCopy = useCallback(() => {
    clipboard.copy(links.url);
  }, [links.url, clipboard]);

  const displayUrl = useMemo(() => {
    if (links.url === null) {
      return null;
    }
    const splittedUrl = links.url.split("/");
    return splittedUrl[splittedUrl.length - 2] + "/index.html";
  }, [links.url]);

  return (
    <ResultZip
      links={links}
      onClipboardCopy={handleClipboardCopy}
      displayUrl={displayUrl}
    />
  );
}

ResultZipBase.propTypes = {
  links: PropTypes.objectOf(PropTypes.oneOfType([() => null, PropTypes.string]))
    .isRequired,
};

export default ResultZipBase;
