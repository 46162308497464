import React from "react";

import styled from "styled-components";

const HeaderGradientUI = styled.div`
  height: 16px;

  background-image: linear-gradient(
    to right,
    ${({ theme }) => theme.colors.viking},
    ${({ theme }) => theme.colors.abbey},
    ${({ theme }) => theme.colors.pink},
    ${({ theme }) => theme.colors.coral}
  );
`;

HeaderGradientUI.displayName = "HeaderGradientUI";

function Gradient() {
  return <HeaderGradientUI />;
}

export default Gradient;
