import React from "react";

import PropTypes from "prop-types";

import File from "./File";

function List({ metadata, currentStatus, onSetCurrentStatus }) {
  return metadata.map((file, index) => {
    return (
      <File
        file={file}
        currentStatus={currentStatus}
        onSetCurrentStatus={onSetCurrentStatus}
        key={`file-${index}`}
      />
    );
  });
}

List.propTypes = {
  metadata: PropTypes.arrayOf(
    PropTypes.shape({
      base64: PropTypes.string,
      dimensions: PropTypes.objectOf(PropTypes.number),
      name: PropTypes.string,
      size: PropTypes.number,
    })
  ).isRequired,
  currentStatus: PropTypes.number.isRequired,
  onSetCurrentStatus: PropTypes.func,
};

List.defaultProps = {
  onSetCurrentStatus: () => null,
};

export default List;
