import React from "react";

import { ReactComponent as UploadIcon } from "@mobsuccess-devops/streamline/custom/asset-manager/upload.svg";

import PropTypes from "prop-types";
import styled from "styled-components";

const ButtonUI = styled.div`
  padding: 48px;
  background: ${({ theme }) => theme.colors.white};
  border-radius: 50%;
  cursor: pointer;
`;

ButtonUI.displayName = "ButtonUI";

const Logo = styled(UploadIcon)`
  width: 100%;
  max-width: 156px;
`;

Logo.displayName = "Logo";

function ButtonUpload({ onClick }) {
  return (
    <ButtonUI onClick={onClick}>
      <Logo />
    </ButtonUI>
  );
}

ButtonUpload.propTypes = {
  onClick: PropTypes.func,
};

ButtonUpload.defaultProps = {
  onClick: () => null,
};

export default ButtonUpload;
