import React from "react";

import PropTypes from "prop-types";
import styled from "styled-components";

import Copy from "../../../Copy";

const TextUI = styled.li`
  margin-left: 16px;
  font-size: 18px;
  font-weight: 500;
`;

TextUI.displayName = "TextUI";

const WarningTextUI = styled(TextUI)`
  color: #ff8c51;
`;
WarningTextUI.displayName = "WarningTextUI";

const ErrorTextUI = styled(TextUI)`
  color: #ff5151;
`;
ErrorTextUI.displayName = "ErrorTextUI";

const BlockUI = styled.div`
  width: 100%;
  padding: 16px;
  border: 1px solid #c2c2c2;
  background: #fafafa;
  border-radius: 8px;
`;

BlockUI.displayName = "BlockUI";

const ParagraphImageUI = styled.div`
  display: flex;
  margin-bottom: 8px;
  color: ${({ theme }) => theme.colors.viking};
`;

ParagraphImageUI.displayName = "ParagraphImageUI";

const LinkUI = styled.a`
  display: flex;
  align-items: center;
  color: inherit;
  text-decoration: none;
`;

LinkUI.displayName = "LinkUI";

const getErrorsBlock = (errors) => (
  <BlockUI key={`error-0`}>
    <ul>
      {errors.map((error, key) => (
        <ErrorTextUI key={`error-0-${key}`}>{error}</ErrorTextUI>
      ))}
    </ul>
  </BlockUI>
);

const getWarningBlocks = (warning) => {
  const keysValue = {
    externalRessources: "Ressource(s) externe(s)",
  };
  const allWarnings = [];

  for (const [key, value] of Object.entries(warning)) {
    const warningBlock = {
      name: keysValue[key] || "Avertissements",
      content: value,
    };
    allWarnings.push(warningBlock);
  }

  return allWarnings.map((element, key) => (
    <BlockUI key={`warning-${key}`}>
      {element.name}
      <ul>
        {element.content.map((elementContent, cKey) => (
          <WarningTextUI key={`warningText-${key}-${cKey}`}>
            {elementContent}
          </WarningTextUI>
        ))}
      </ul>
    </BlockUI>
  ));
};
function ResultZip({ links, onClipboardCopy, displayUrl }) {
  return (
    <div>
      {links.errors ? (
        getErrorsBlock(links.errors)
      ) : (
        <div>
          <ParagraphImageUI>
            <LinkUI href={links.url} target="_blank">
              {displayUrl}
            </LinkUI>
            <Copy onClick={onClipboardCopy} />
          </ParagraphImageUI>
          {links.warning ? getWarningBlocks(links.warning) : null}
        </div>
      )}
    </div>
  );
}

ResultZip.propTypes = {
  onClipboardCopy: PropTypes.func.isRequired,
  displayUrl: PropTypes.string,
  links: PropTypes.objectOf(PropTypes.oneOfType([() => null, PropTypes.string]))
    .isRequired,
};

ResultZip.defaultProps = {
  displayUrl: "",
};

export default ResultZip;
