import React, { useCallback, useEffect, useState } from "react";

import { useJwtToken } from "@mobsuccess-devops/react-mobsuccess-authentication";

import PropTypes from "prop-types";

import { uploadFile } from "../../../../features/upload";

import File from "./File";

function FileBase({ file, currentStatus, onSetCurrentStatus }) {
  const [isUploading, setIsUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [result, setResult] = useState(null);
  const jwtToken = useJwtToken();

  const handleUploadProgress = useCallback((progressEvent) => {
    setUploadProgress(
      Math.floor((progressEvent.loaded / progressEvent.total) * 100)
    );
  }, []);

  useEffect(() => {
    async function startUpload() {
      const sendFiles = await uploadFile(
        { file, jwtToken },
        handleUploadProgress
      );
      setResult(sendFiles);
      onSetCurrentStatus(3);
    }
    if (currentStatus === 2 && !isUploading) {
      setIsUploading(true);
      startUpload();
    }
  }, [
    currentStatus,
    file,
    handleUploadProgress,
    isUploading,
    onSetCurrentStatus,
    jwtToken,
  ]);

  return (
    <File
      file={file}
      result={result}
      currentStatus={currentStatus}
      uploadProgress={uploadProgress}
    />
  );
}

FileBase.propTypes = {
  file: PropTypes.shape({
    base64: PropTypes.string,
    dimensions: PropTypes.objectOf(PropTypes.number),
    name: PropTypes.string,
    size: PropTypes.number,
  }).isRequired,
  currentStatus: PropTypes.number.isRequired,
  onSetCurrentStatus: PropTypes.func.isRequired,
};

export default FileBase;
