import axios from "axios";

const apiBaseUrl =
  process.env.REACT_APP_BACKEND_URL ||
  "https://xe1fbg2kxi.execute-api.eu-central-1.amazonaws.com";

const axiosWrapper = async ({ jwtToken, file }, handleUploadProgress) => {
  const config = {
    method: "post",
    baseURL: apiBaseUrl,
    url: "/upload",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer jwt:${jwtToken}`,
    },
    data: {
      file,
    },
    onUploadProgress: (progressEvent) => {
      handleUploadProgress(progressEvent, file.name);
    },
  };
  try {
    const axiosResult = await axios.request(config);
    return axiosResult.data;
  } catch (e) {
    if (e.response) {
      return e.response.data;
    }
    return {
      type: file.type,
      errors: ["Merci de réeassayer dans quelques minutes."],
    };
  }
};

export const uploadFile = async ({ jwtToken, file }, handleUploadProgress) => {
  return axiosWrapper({ jwtToken, file }, handleUploadProgress);
};

export const uploadFiles = async (
  { jwtToken, files },
  handleUploadProgress
) => {
  const axiosRequests = files.map((file) => {
    return axiosWrapper({ jwtToken, file }, handleUploadProgress);
  });
  return Promise.all(axiosRequests);
};
