/* eslint-disable @mobsuccess-devops/mobsuccess/layout */
import React from "react";

import { configureMobsuccessAuthentication } from "@mobsuccess-devops/react-mobsuccess-authentication";

import { render } from "react-dom";

import App from "./App";

configureMobsuccessAuthentication();

render(<App />, document.getElementById("root"));
