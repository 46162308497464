import React from "react";

import PropTypes from "prop-types";

import Result from "../Result";

function ResultMedia({ links }) {
  if (!links.errors && links.url !== null) {
    return <Result url={links.url} />;
  }
  return <p>{`${`Une erreur s'est produite.`}`}</p>;
}

ResultMedia.propTypes = {
  links: PropTypes.objectOf(PropTypes.oneOfType([() => null, PropTypes.string]))
    .isRequired,
};

export default ResultMedia;
