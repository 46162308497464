import React from "react";

import PropTypes from "prop-types";
import styled from "styled-components";

const TitleUI = styled.h1`
  color: ${({ theme }) => theme.colors.gray.normalGray};
  font-size: ${({ theme }) => theme.fontSize.titleMobile};
  text-align: center;

  @media screen and (min-width: 414px) {
    font-size: ${({ theme }) => theme.fontSize.title};
  }
`;

TitleUI.displayName = "TitleUI";

function Title({ text }) {
  return <TitleUI>{text}</TitleUI>;
}

Title.propTypes = {
  text: PropTypes.string,
};

Title.defaultProps = {
  text: "",
};

export default Title;
