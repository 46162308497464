import React from "react";

import PropTypes from "prop-types";
import styled from "styled-components";

import Header from "./Header";
import List from "./List";

const MainFilesUI = styled.main`
  display: flex;
  overflow: scroll;
  width: 80%;
  max-height: 70vh;
  flex-direction: column;
  padding: 20px;
  background: ${({ theme }) => theme.colors.white};
  border-radius: 20px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
`;

MainFilesUI.displayName = "MainFilesUI";
function Files({ metadata, currentStatus, onSetCurrentStatus }) {
  return (
    <MainFilesUI>
      <Header metadata={metadata} />
      <List
        metadata={metadata}
        currentStatus={currentStatus}
        onSetCurrentStatus={onSetCurrentStatus}
      />
    </MainFilesUI>
  );
}

Files.propTypes = {
  metadata: PropTypes.arrayOf(
    PropTypes.shape({
      base64: PropTypes.string,
      dimensions: PropTypes.objectOf(PropTypes.number),
      name: PropTypes.string,
      size: PropTypes.number,
    })
  ).isRequired,
  currentStatus: PropTypes.number.isRequired,
  onSetCurrentStatus: PropTypes.func,
};

Files.defaultProps = {
  onSetCurrentStatus: () => null,
};

export default Files;
