/* eslint-disable @mobsuccess-devops/mobsuccess/variables */
import React from "react";

import { MobsuccessAuthentication } from "@mobsuccess-devops/react-mobsuccess-authentication";

import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { ToastProvider } from "react-toast-notifications";
import styled, { ThemeProvider } from "styled-components";

import Gradient from "./components/Gradient";
import Uploader from "./steps/Uploader";
import GlobalStyle from "./theme/GlobalStyle";
import theme from "./theme/theme";

const WrapperUI = styled.div`
  position: relative;
  height: calc(100vh - 16px);
  background: ${theme.colors.gray.lightGray};
`;

WrapperUI.displayName = "WrapperUI";

export default function App() {
  return (
    <MobsuccessAuthentication>
      <DndProvider backend={HTML5Backend}>
        <ThemeProvider theme={theme}>
          <ToastProvider>
            <Router>
              <GlobalStyle />
              <Gradient />
              <Switch>
                <WrapperUI>
                  <Route exact path="/" component={Uploader} />
                </WrapperUI>
              </Switch>
            </Router>
          </ToastProvider>
        </ThemeProvider>
      </DndProvider>
    </MobsuccessAuthentication>
  );
}
