import React, { useMemo } from "react";

import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Tooltip from "@material-ui/core/Tooltip";
import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined";

import PropTypes from "prop-types";
import styled from "styled-components";

const Icon = styled(FileCopyOutlinedIcon)`
  margin-left: 32px;
  color: ${({ theme }) => theme.colors.gray.numberGray};
  cursor: pointer;
`;

function Copy({ open, onTooltipClose, onIconClick }) {
  const popperProps = useMemo(() => {
    return {
      disablePortal: true,
    };
  }, []);

  return (
    <ClickAwayListener onClickAway={onTooltipClose}>
      <div>
        <Tooltip
          PopperProps={popperProps}
          onClose={onTooltipClose}
          open={open}
          disableFocusListener
          disableHoverListener
          disableTouchListener
          title="Lien copié"
          placement="top"
        >
          <Icon onClick={onIconClick} />
        </Tooltip>
      </div>
    </ClickAwayListener>
  );
}

Copy.propTypes = {
  open: PropTypes.bool.isRequired,
  onTooltipClose: PropTypes.func.isRequired,
  onIconClick: PropTypes.func.isRequired,
};

export default Copy;
