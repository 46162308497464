import React from "react";

import { ReactComponent as SvgLogoIcon } from "@mobsuccess-devops/streamline/custom/asset-manager/mobsuccess_logo.svg";

import styled from "styled-components";

const LogoUI = styled(SvgLogoIcon)`
  width: 100%;
  max-width: 260px;

  @media screen and (min-width: 414px) {
    max-width: 370px;
  }
`;
function Logo() {
  return <LogoUI />;
}

export default Logo;
